<template>
	<ValidationObserver>
		<form @submit.prevent="saveData">
			<b-card title="Update Inspiration">
				<div class="form-group form-row">
					<label for="title" class="col-4 col-form-label">Title:</label>
					<div class="col">
						<validation-provider
							name="title"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								id="title"
								type="text" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								class="form-control" 
								placeholder="Title.."
								v-model="formPayload.thread_title"
							>
							<small
								v-for="(validation, index) in validations.thread_title"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label for="question" class="col-4 col-form-label">Question:</label>
					<div class="col">
						<validation-provider
							name="title"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<textarea 
								id="question" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								class="form-control" 
								placeholder="Title.."
								v-model="formPayload.thread_question"
							/>
							<small
								v-for="(validation, index) in validations.thread_question"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label for="category" class="col-4 col-form-label">Category:</label>
					<div class="col">
						<v-select
							id="category"
							v-model="formPayload.category"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="content_category_name"
							placeholder="Pilih Category"
							:options="category"
							@change="inputSubCategory($event.content_category_slug)" 
						/>
						<small
							v-for="(validation, index) in validations.category"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</div>
				</div>
				<div class="form-group form-row">
					<label for="subCategory" class="col-4 col-form-label">Sub Category:</label>
					<div class="col">
						<v-select
							id="subCategory"
							v-model="formPayload.sub_category"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="content_subcategory_name"
							placeholder="Pilih Sub Category"
							:options="subCategory"
						/>
						<small
							v-for="(validation, index) in validations.sub_category"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</div>
				</div>
			</b-card>
			<b-col cols="12" class="p-0 mt-2">
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Save
				</button>
				&nbsp;
				&nbsp;
				<a
					@click="handleBackButton()"
					variant="secondary"
					class="btn waves-effect waves-float waves-light btn-secondary"
				>
					Cancel
				</a>
			</b-col>
		</form>
	</ValidationObserver>
</template>

<script>
import { 
	BRow, 
	BCol, 
	BCard,
	BFormFile, 
	BButton, 
	BSpinner 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { VueEditor } from "vue2-editor";
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormFile,
		VueEditor,
		vSelect,
		flatPickr,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			required,
			isLoading: false,
			formPayload: {
				thread_title: '',
				thread_question: '',
				category: '',
				sub_category: '',
			},
			snowOption: {
				theme: 'snow',
			},
			category: [],
			subCategory: [],
			validations: ''
		}
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},

	created() {
		this.loadData()
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		inputSubCategory(slug) {
			this.$http.get(`/admin/sub-category-contents?content_category_slug=${slug}`)
			.then(response => {
				this.subCategory = response.data.data
			})
		},
		categoryName() {
			this.$http.get('/admin/category-threads')
			.then(response => {
				this.category = response.data.data
			})
		},
		async subCategoryName() {
			await this.$http.get('/admin/sub-category-threads')
			.then(response => {
				this.subCategory = response.data.data
			})
		},
		loadData() {
			const params = this.$route.params.slug
			this.$http.get('/admin/inspirations/' + params)
			.then(response => {
				this.formPayload = response.data.data
				this.thumbnail_url = this.formPayload.thumbnail
				this.filesUrl = this.formPayload.files
			}).then(() => {
				this.categoryName();
				this.subCategoryName();
			}).catch(error => {
			})
		},

		preparePayload() {
			const form = new FormData()
			if (this.formPayload.category !== null && this.formPayload.subCategory !== null) {
				this.formPayload.id_category_content = this.formPayload.category ? this.formPayload.category.id : ''
				this.formPayload.id_subcategory_content = this.formPayload.sub_category ? this.formPayload.sub_category.id : ''
			}
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {	
					if(key != 'thumbnail' && key != 'files' && key != 'sub_category' && key != 'category'){
						form.append(key, this.formPayload[key])
					} else {
						if(typeof this.formPayload.thumbnail != 'string' && this.formPayload.thumbnail != null) {
							form.append('thumbnail', this.formPayload['thumbnail'])
						}
					}
				}
			}
			if (this.formPayload.files) {
				for (let index = 0; index < this.formPayload.files.length; index++) {
					const element = this.formPayload.files[index];
					if (typeof element.content != 'string') {
						form.append('files[]', element)
					}
				}
			}
			return form;
		},

		saveData() {
			const id = this.$route.params.id
			var form = this.preparePayload();
			this.isLoading = true
			form.append('_method', 'PATCH');
				this.$http.post(`/admin/threads/${id}`, form , {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				successNotification(this, 'Success', 'Tanya Master Updated!')
				this.$router.push({ name: 'tanya-master' })
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages.length > 0) {
				errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				}
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama', this.validations.name.join(' '))
				}
				this.isLoading = false
			})
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>